import { Routes, Route } from "react-router-dom";
import { Home } from "./components/Home";
import { Nav } from "./components/Nav";
import { Footer } from "./components/Footer";
import { ProductDetail, Products } from "./components/Products";

import { NotFound } from "./components/NotFound";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Products" element={<Products />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/detail" element={<ProductDetail />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
